<template lang="pug">
el-drawer(:visible.sync="show" direction="rtl" size="30%" @closed="close")
    div(slot="title")
        .title {{ title }}
    .drawer-content
        el-form(:model="form" :rules="rules" ref="categoryForm")
            el-form-item(prop="categoryName" v-if="category && category.name")
                .label(slot="label") Родительская категория
                el-input(
                    :disabled="true"
                    :value="category.pathNames.length ? category.pathNames.join(' / ') : category.name"
                    placeholder="Родительская категория"
                    autocomplete="off"
                )
            el-form-item.form-item(prop="name")
                .label(slot="label") Название
                el-select.full(
                    v-if="mode === 'create'"
                    v-model="form.name"
                    filterable allow-create 
                    default-first-option 
                    placeholder="Выберите или создайте категорию" 
                    :loading="loagingCategoriesGetter"
                    clearable)
                    el-option(
                        v-if="categoriesGetter && categoriesGetter.result" 
                        v-for='category of categoriesGetter.result' 
                        :key="category.id" 
                        :label="category.name" 
                        :value="category.id"
                    )
                el-input(
                    v-if="mode === 'edit'"
                    v-model="form.name"
                    placeholder="Введите название категории"
                    autocomplete="off"
                )

            el-form-item.form-item(prop="type" v-if="!isExistingCategory")
                .label(slot="label") Тип
                el-select.full(v-model="form.type" placeholder="Выберите тип категории")
                    el-option(label='Без типа' :value="null")
                    el-option(label='Расход' value="expense")
                    el-option(label='Доход' value="income")
            el-form-item
                .actions
                    el-button(@click="close") Отмена
                    el-button(type="primary" @click="submit" :loading="loading") 
                        | {{ mode === 'create' ? 'Добавить' : 'Редактировать' }}

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

const initialForm = {
    name: '',
    type: null,
    path: null,
};

export default {
    name: 'CategoryDrawer',
    data: () => ({
        mode: 'create',
        category: null,
        loading: false,
        form: { ...initialForm },
        rules: {
            name: {
                required: true,
                message: 'Введите название категории',
                trigger: 'blur',
            },
        },
        show: false,
    }),
    computed: {
        ...mapGetters('transactionCategoryRelations', [
            'structureGetter',
            'loagingGetter',
        ]),
        ...mapGetters('transactionCategories', [
            'categoriesGetter',
            'loagingCategoriesGetter',
        ]),
        isExistingCategory() {
            return typeof this.form.name === 'number';
        },
        title() {
            if (this.mode === 'create' && !this.category)
                return 'Добавить корневую категорию';
            if (this.mode === 'create' && this.category)
                return `Добавить категорию`;
            if (this.mode === 'edit' && this.category)
                return `Редактировать категорию`;
            return 'Добавить категорию';
        },
    },
    methods: {
        ...mapActions('transactionCategoryRelations', [
            'getStructureAction',
            'createRelationAction',
        ]),
        ...mapActions('transactionCategories', [
            'getListAction',
            'updateCategoryAction',
        ]),
        open(category) {
            this.show = true;
            this.setCategory(category);
            this.getListAction();
        },
        close() {
            this.show = false;
            this.form = { ...initialForm };
            this.$refs.categoryForm.resetFields();
            this.$refs.categoryForm.clearValidate();
            this.mode = 'create';
            this.category = null;
        },
        setCategory(category) {
            this.category = category;
            this.form.path = category && category.path ? category.path : null;
        },
        setMode(mode) {
            this.mode = mode;
            this.form.name = this.category.name;
            this.form.type = this.category.type;
        },
        submit() {
            this.$refs.categoryForm.validate(async valid => {
                if (valid) {
                    this.loading = true;
                    try {
                        if (this.mode === 'create') {
                            await this.createRelationAction(this.form);
                        } else {
                            await this.updateCategoryAction({
                                ...this.form,
                                categoryId: this.category.categoryId,
                            });
                        }
                        //- this.close();
                        this.$emit('onSubmit');
                    } finally {
                        this.loading = false;
                    }
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.drawer-content {
    padding: 20px;
    margin-top: -40px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.subtitle {
    font-size: 14px;
}

.label {
    font-size: 14px;
    font-weight: bold;
    display: inline;
}

.full {
    width: 100%;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.form-item {
    margin-top: -15px;
}
</style>
