/**
 * @author feod0r
 */
import { LOCAL_STORAGE_TRANSACTIONS_FILTERS_KEY } from '@/utils/constants';

export const initialAuthState = () => ({ status: false, user: {}, rights: [] });
export const initialTransactionDataFilters = () => {
    let localStorageFilters = localStorage.getItem(
        LOCAL_STORAGE_TRANSACTIONS_FILTERS_KEY,
    );
    let filters = {
        author: null,
        payoutSumm: null,
        payoutType: null,
        payoutAuthor: null,
        accounts: [],
        merchants: [],
        categoryPath: [],
        datePickerFilterType: 'monthrange',
        showOnlyEdited: null,
    };
    if (localStorageFilters) {
        filters = JSON.parse(localStorageFilters);
    }

    return filters;
};

export default {
    isInitialLoaded: false,
    isInitialLoadRequestPending: false,
    auth: initialAuthState(),

    propertiesData: {
        account: [],
        project: [],
        category: [],
        tag: [],
        merchants: [],
        geo: [],
    },

    group: {
        isLoading: false,
        list: [],
        selected: null,
    },

    transactionData: {
        isLoading: false,
        filters: initialTransactionDataFilters(),
        sortOrders: {
            date: null,
            amount: null,
        },
        list: [],
        totalCount: 0,
        pageSize: 0,
        currentPage: 1,
        filterAuthors: [],
        totalRecievedTransfersAmount: 0,
        totalTransfersAmount: 0,
        totalExpenses: 0,
        totalIncome: 0,
        datePickerFilterType: 'monthrange',
        showOnlyEdited: false,
    },

    merchantBalancesData: {
        isLoading: false,
        rows: [],
    },
};
