<template lang="pug">
.create-transfer-form
  el-dialog(
    title="Создание перевода",
    :visible="isVisible",
    @close="$emit('updateVisibleStatus', false)",
    @open="resetForm",
    :close-on-click-modal="false",
    :destroy-on-close="true"
  )
    el-form(:model="form", :rules="rules", ref="form")
      el-form-item(prop="accountType", label="Тип перевода")
      el-form-item(prop="accountId", label="Счет списания")
        el-select.select(
          v-model="form.accountId",
          placeholder="Выберите счет",
          filterable
        )
          el-option-group(
            label="Аккаунты",
            v-if="propertiesData.account && propertiesData.account.length"
          )
            el-option(
              v-for="account in propertiesData.account",
              :label="account.name",
              :value="account.id"
            )
              span(style="float: left") {{ account.name }}
              span(style="float: right; color: #8492a6; font-size: 0.85em") 
                | {{ CURRENCY_CHAR_MAP[account.currency] || account.currency }}
          el-option-group(
            label="Мерчанты",
            v-if="propertiesData.merchants && propertiesData.merchants.length"
          )
            el-option(
              v-for="merchant in propertiesData.merchants",
              :label="merchant.name",
              :value="`${merchant.name}--${merchant.currency}`"
            )
              span(style="float: left") {{ merchant.name }}
              span(style="float: right; color: #8492a6; font-size: 0.85em") 
                | {{ CURRENCY_CHAR_MAP[merchant.currency] || merchant.currency }}

      el-form-item(prop="accountId", label="Счет получения")
        el-select.select(
          v-model="form.targetAccountId",
          placeholder="Выберите счет",
          filterable
        )
          el-option-group(
            label="Аккаунты",
            v-if="propertiesData.account && propertiesData.account.length"
          )
            el-option(
              v-for="account in propertiesData.account",
              :label="account.name",
              :value="account.id"
            )
              span(style="float: left") {{ account.name }}
              span(style="float: right; color: #8492a6; font-size: 0.85em") 
                | {{ CURRENCY_CHAR_MAP[account.currency] || account.currency }}
          el-option-group(
            label="Мерчанты",
            v-if="propertiesData.merchants && propertiesData.merchants.length"
          )
            el-option(
              v-for="merchant in propertiesData.merchants",
              :label="merchant.name",
              :value="`${merchant.name}--${merchant.currency}`"
            )
              span(style="float: left") {{ merchant.name }}
              span(style="float: right; color: #8492a6; font-size: 0.85em")
                | {{ CURRENCY_CHAR_MAP[merchant.currency] || merchant.currency }}

      .group
        el-form-item.flex-item(prop="amount", label="Сумма перевода")
          el-input(
            @change="getConvertedAmount('amount')",
            v-model.number="form.amount",
            placeholder="Укажите сумму",
            autocomplete="off"
          )
            template(slot="append") {{ currency.char }}
        el-form-item.align(
          v-if="currencyTarget.char !== currency.char",
          prop="converted",
          style="width: 150px"
        )
          el-input(v-model.number="form.converted.amount" autocomplete="off")
            template(slot="append") {{ currencyTarget.char }}

      .group
        el-form-item.flex-item(
          prop="fee",
          label="Комиссия за перевод (не обязательно)"
        )
          el-input(
            @change="getConvertedAmount('fee')",
            v-model.number="form.fee",
            placeholder="Укажите комиссию",
            autocomplete="off"
          )
            template(slot="append") {{ currency.char }}
        el-form-item.align(
          v-if="currencyTarget.char !== currency.char",
          prop="converted",
          style="width: 150px"
        )
          el-input(v-model.number="form.converted.fee" autocomplete="off")
            template(slot="append") {{ currencyTarget.char }}
      
      el-form-item(
        prop="date"
        label='Дата операции'
      )
        el-date-picker(
          style="width:100%"
          v-model="form.date"
          type="date"
          placeholder="Выберите дату"
        )  

      el-form-item(prop="comment", label="Комментарий")
        el-input(
          v-model="form.comment",
          type="textarea",
          :rows="2",
          placeholder="Комментарий (не обязательно)",
          autocomplete="off"
        )

    span.dialog-footer(slot="footer")
      el-button(
        @click="$emit('updateVisibleStatus', false)",
        :disabled="isLoading"
      ) Отмена
      el-button(
        type="primary",
        :loading="isLoading",
        @click="submitTransaction"
      ) Подтвердить
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import api from '@/utils/api';
import { CURRENCY_CHAR_MAP, DEFAULT_CURRENCY } from '@/utils/constants';

const initFormData = () => ({
    accountId: undefined,
    targetAccountId: undefined,
    amount: 0,
    fee: 0,
    comment: '',
    accountType: 'accounting',
    date: null,
    converted: { amount: 0, fee: 0 },
});

export default {
    name: 'CreateTransferForm',
    props: { isVisible: Boolean },
    data: () => ({
        isLoading: false,
        form: initFormData(),

        rules: {
            accountId: {
                required: true,
                message: 'Заполните поле',
                trigger: 'change',
            },
            targetAccountId: {
                required: true,
                message: 'Заполните поле',
                trigger: 'change',
            },
            amount: {
                required: true,
                message: 'Заполните поле',
                trigger: 'change',
            },
            fee: {
                required: true,
                message: 'Заполните поле',
                trigger: 'change',
            },
        },
        CURRENCY_CHAR_MAP,
    }),
    computed: {
        ...mapGetters(['propertiesData', 'transactionData', 'groupId']),
        currency() {
            const currency = this.findCurrency('accountId');

            return { currency, char: CURRENCY_CHAR_MAP[currency] || currency };
        },
        currencyTarget() {
            const currency = this.findCurrency('targetAccountId');

            return { currency, char: CURRENCY_CHAR_MAP[currency] || currency };
        },
    },
    methods: {
        ...mapActions([
            'loadTransactionData',
            'loadPropertiesData',
            'utilsConvertCurrency',
        ]),
        findCurrency(prop) {
            let value = this.form[prop];
            if (!value) return DEFAULT_CURRENCY;
            let storeObj = parseInt(value) ? 'account' : 'merchants';
            if (storeObj === 'merchants') {
                value = value.split('--');
            }
            const result = this.propertiesData[storeObj].find(data =>
                storeObj === 'account'
                    ? +data.id === +value
                    : data.name === value[0] && data.currency === value[1],
            );
            return result ? result.currency : DEFAULT_CURRENCY;
        },
        async getConvertedAmount(type) {
            const converted = await this.utilsConvertCurrency({
                amount: this.form[type],
                from: this.currency.currency,
                to: this.currencyTarget.currency,
            });
            this.form.converted[type] = converted;
        },
        resetForm() {
            this.form = initFormData();
        },
        submitTransaction() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    this.isLoading = true;
                    try {
                        await api.post('/transaction/transfer', {
                            ...this.form,
                            amount: this.form.amount,
                            groupId: this.groupId,
                        });

                        await Promise.all([
                            this.loadTransactionData(
                                this.transactionData.currentPage,
                            ),
                            this.loadPropertiesData(),
                        ]);
                        this.$emit('updateVisibleStatus', false);
                    } finally {
                        this.isLoading = false;
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss">
.group {
    display: flex;
    justify-content: stretch;
    .flex-item {
        flex-basis: 100%;
    }
    // flex-basis: auto !important;
    .align {
        flex-basis: 250px;
        margin-top: 21px;
        margin-left: 5px;
    }
}
.create-transfer-form {
    .select {
        width: 100%;
    }

    .el-form-item {
        margin-bottom: 15px;
    }

    .el-form-item__label {
        margin-bottom: 0;
        padding-bottom: 0;
        line-height: inherit;
    }
}
</style>
