<template lang="pug">
.import-excel-form
    el-dialog(
        title="Импорт файла"
        :visible.sync="show"
        :destroy-on-close="true"
        @close="close"
    )
        el-form(:model="form" ref="form")
            el-upload(
                drag=""
                action="/"
                :on-change="uploadExcelImport"
                :auto-upload="false"
                :limit="1"
                :on-remove="onRemove"
            )
                i.el-icon-upload
                .el-upload__text
                | Перетяните или &nbsp;
                em кликните для загрузки
            

        span.dialog-footer(slot="footer")
            el-button(@click="close" :disabled="isLoading") Отменить
            el-button(type="primary" :loading="isLoading" @click="importFile") Отправить

</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ImportExcelForm',
    data() {
        return {
            isLoading: false,
            show: false,
            form: {
                file: '',
            },
        };
    },
    computed: {
        ...mapGetters(['groupId']),
    },
    methods: {
        ...mapActions(['importExcel']),
        async uploadExcelImport(file) {
            this.form.file = null;
            const reader = new FileReader();

            reader.readAsDataURL(file.raw);

            reader.onloadend = () => {
                this.form.file = reader.result;
            };
        },
        async importFile() {
            this.isLoading = true;
            try {
                await this.importExcel({
                    file: this.form.file,
                    groupId: this.groupId,
                });

                this.$notify({
                    title: 'Успешно',
                    message: 'Транзакции добавлены',
                    type: 'success',
                    duration: 4000,
                });
            } catch (e) {
                this.$notify({
                    title: 'Ошибка',
                    message: 'Транзакции не добавлены',
                    type: 'error',
                    duration: 4000,
                });
            } finally {
                this.$emit('updateVisibleStatus');
                this.isLoading = false;
            }
        },
        onRemove() {
            this.form.file = null;
        },
        open() {
            this.show = true;
        },
        close() {
            this.show = false;
        },
    },
};
</script>

<style lang="scss">
.import-excel-form {
    .el-dialog__wrapper {
        > div {
            max-width: 450px;

            .el-upload,
            .el-upload-dragger {
                width: 100%;
            }
        }
    }

    .el-upload-list {
        width: 100%;
    }

    .el-upload-list__item {
        margin-top: 0;
        margin-left: 10px;
    }
}
</style>
