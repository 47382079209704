<template lang="pug">
  .account-table
    el-table(
      :data="propertiesData.account"
      @row-click="selectAccount"
    )
      el-table-column
        template(slot='header')
          | Капитал
        template(slot-scope='scope')
          | {{ scope.row.name }}

      el-table-column(align='right')
        template(
          slot='header'
          slot-scope='scope'
        )
          | {{ totalBalance | money }}
        template(slot-scope='scope')
          | {{ scope.row.amount | money(scope.row.currency) }}

      el-table-column(align='right')
        template(
          slot='header'
          slot-scope='scope'
        )
          | Фактический баланс
        template(slot-scope='scope')
          | {{ scope.row.actualBalance | money(scope.row.currency) }}
</template>

<script>
import { mapGetters } from 'vuex';

import { CURRENCY_CHAR_MAP } from '@/utils/constants';

export default {
    name: 'AccountTable',
    data() {
        return {
            CURRENCY_CHAR_MAP,
        };
    },
    computed: {
        ...mapGetters(['propertiesData', 'transactionData']),
        totalBalance() {
            return this.propertiesData.account.reduce(
                (acc, { amount }) => acc + amount,
                0,
            );
        },
    },
    methods: {
        selectAccount({ id }) {
            const isAccountAlreadySelected = this.transactionData.filters.account.includes(
                id,
            );

            this.$store.commit('resetTransactionFilter');
            if (!isAccountAlreadySelected)
                this.$store.commit('addTransactionFilter', {
                    type: 'account',
                    id,
                });
            this.$store.dispatch('loadTransactionData');
        },
    },
};
</script>

<style scoped lang="scss">
.account-table {
}
</style>
