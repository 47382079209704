<template lang="pug">
  .form
    el-dialog(
      title="Укажите причину"
      :visible="isVisible"
      @close="$emit('updateReasonVisibleStatus', false)"
      :destroy-on-close="true"
    )
      el-form(:model="form" :rules="rules" ref="form")
        el-form-item(prop="reason" label='Название')
            el-input(v-model="form.reason" placeholder="Укажите название" autocomplete="off")

      span.dialog-footer(slot="footer")
        el-button(@click="$emit('updateReasonVisibleStatus', false)" :disabled="isLoading") Отмена
        el-button(type="primary" :loading="isLoading" @click="editTransaction") Отправить

</template>

<script>
/* eslint-disable vue/require-prop-types */
export default {
    name: 'InsertReasonForm',
    props: ['isVisible', 'isLoading'],
    data() {
        return {
            form: {
                reason: '',
            },
            rules: {
                reason: {
                    trigger: 'change',
                    required: true,
                    message: 'Введите причину',
                },
            },
        };
    },
    methods: {
        editTransaction() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    const { reason } = this.form;
                    this.$emit('sendEditingTransaction', reason);
                    this.$emit('updateReasonVisibleStatus', false);
                    this.form.reason = '';
                    this.$refs.form.clearValidate();
                }
            });
        },
    },
};
</script>
