/**
 * @author feod0r
 */

export const DEFAULT_CURRENCY = 'RUB';
export const CURRENCY_CHAR_MAP = {
    RUB: '₽',
    USD: '$',
    EUR: '€',
    BTC: '₿',
    USDT: '₮',
    UAH: '₴',
    AMD: '֏',
    INR: '₹',
    BRL: 'R$',
    KZT: '₸',
    RSD: 'din',
    UZS: 'сум',
    TJS: 'SM',
    TRY: '₺',
    NGN: '₦',
    AZN: '₼',
    KES: 'KSh',
    MXN: 'MXN',
    ZAR: 'R',
    PLN: 'zł',
    AED: 'د.إ',
};
export const CURRENCY_LIST = Object.keys(CURRENCY_CHAR_MAP);

export const CATEGORY_TYPE_MAP = {
    income: 'Доход',
    expense: 'Расход',
};
export const CATEGORY_TYPE_LIST = ['income', 'expense'];

export const GROUP_KEY = 'group_key';

export const MERCHANTS_LIST = {
    apspay: ['RUB', 'KZT', 'USD', 'EUR'],
    interkassa: ['UAH', 'USD', 'EUR', 'KZT', 'TRY', 'NGN', 'ZAR', 'KES', 'UGX'],
    moneygo: ['USD', 'EUR', 'USDT'],
    paze: ['USD', 'EUR'],
    infernopay_81: ['USD'],
    constantpos: ['EUR'],
    paykassma: ['INR', 'KES', 'BDT', 'PKR'],
    ligdicash: ['CFA'],
    instapays: ['TRY'],
    paystudio: ['USD'],
    b2binpay: ['USDT'],
    paypound: ['USD'],
    payturka: ['USD', 'TRY'],
    realisto: ['EUR'],
    unlimint: ['EUR'],
    gate2way: ['INR'],
    onewin: ['INR', 'BRL'],
    rixsus: ['USD', 'BRL'],
    billine: ['USD', 'UAH'],
    getpay: ['RUB'],
    impaya: ['EUR'],
    'Get-x': ['RUB'],
    decard: [
        'TJS',
        'UZS',
        'BRL',
        'EUR',
        'INR',
        'PKR',
        'CLP',
        'KZT',
        'USD',
        'PEN',
    ],
    monetix: [
        'USD',
        'USDT',
        'NGN',
        'PKR',
        'INR',
        'IDR',
        'RUB',
        'KES',
        'GHS',
        'PHP',
    ],
    PayPal: ['USD'],
    blackrabbit: ['EUR', 'INR'],
    einpayz: ['INR'],
    payop: ['EUR'],
    innopay: ['KZT'],
    blablapay: ['RUB'],
    cryptomus: ['USDT'],
    clapay: ['USD'],
    omni: ['USD'],
    eazype: ['INR'],
    appex: ['USD', 'USDT', 'AZN', 'EUR'],
    pne: ['INR'],
    mpay: ['EUR'],
    sbpay: ['RUB'],
    okootc: ['RUB'],
    eiziopay: ['USD'],
    smartcard: ['AZN'],
    emanat: ['AZN'],
    m10: ['AZN'],
    SkinSale: ['USD', 'USDT'],
    Dragontech: ['KRW'],
    Payplanet: ['USD', 'EUR'],
    airtm: ['USD'],
    Vlito: ['RUB'],
    p2pay: ['USDT'],
    Smartpropay: ['AZN'],
    Fixturka: ['TRY'],
    Rechcompt: ['XOF'],
    GateExpress: ['BRL'],
    Reachcompt: ['XOF'],
    Paycos: ['BRL'],
    Spicy: ['USD'],
    Skinify: ['USDT'],
    Luqapay: ['EUR', 'TRY'],
    PlusPay: ['EUR', 'USDT'],
    oneRoadPayments: ['HKD'],
    Heropayment_UPX: ['USDT'],
    Dusupay: ['XOF'],
    SpicyCash_UPX: ['USDT'],
    NoWallet_CI: ['XOF'],
    NoWallet_BF: ['XOF'],
    NoWallet_ML: ['XOF'],
    NoWallet_SN: ['XOF'],
    ConstantPayment: ['USD'],
    localpayment: ['USD'],
    Monetix_UPX: ['RUB'],
};

export const PAYOUT_TYPES = {
    income: 'Доход',
    expense: 'Расход',
    transferTo: 'Получено',
    transferFrom: 'Списано',
};

export const LOCAL_STORAGE_TRANSACTIONS_FILTERS_KEY = 'transactions-filter';
