import api from '@/utils/api';

const state = {
    structure: null,
    loading: false,
};
const actions = {
    async getStructureAction({ commit, state }, filters) {
        if (filters && filters.forSelect && state.structure) return;
        commit('SET_LOADING', true);
        const { data } = await api.get(
            '/transaction-category-relations/structure',
            {
                params: filters,
            },
        );

        commit('SET_STRUCTURE', data);

        commit('SET_LOADING', false);

        return data;
    },
    async createRelationAction(_, form) {
        const { data } = await api.post(
            '/transaction-category-relations',
            form,
        );

        return data;
    },
};
const mutations = {
    SET_LOADING(state, value) {
        state.loading = value;
    },
    SET_STRUCTURE(state, data) {
        state.structure = data;
    },
};
const getters = {
    loagingGetter(state) {
        return state.loading;
    },
    structureGetter(state) {
        return state.structure;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
