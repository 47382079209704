import api from '@/utils/api';

const state = {
    categories: [],
    loading: false,
};
const actions = {
    async getListAction({ commit }) {
        commit('SET_LOADING', true);
        const { data } = await api.get('/transaction-categories');

        commit('SET_CATEGORIES', data);

        commit('SET_LOADING', false);

        return data;
    },
    async updateCategoryAction(_, form) {
        const { data } = await api.put('/transaction-categories', form);

        return data;
    },
    async changeDeletedCategoryAction(form) {
        const { data } = await api.patch(
            '/transaction-categories/delete',
            form,
        );

        return data;
    },
};
const mutations = {
    SET_LOADING(state, value) {
        state.loading = value;
    },
    SET_CATEGORIES(state, data) {
        state.categories = data;
    },
};
const getters = {
    loagingCategoriesGetter(state) {
        return state.loading;
    },
    categoriesGetter(state) {
        return state.categories;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
