<template lang="pug">
  .header
    el-row
      el-col(:span='11')
        el-menu.menu(mode='horizontal' :router="true" :default-active="activeLink")
          template(v-for='route in routeList()')
            el-menu-item(v-if="route.meta.title" :index='route.path')
              | {{ route.meta.title }}

      el-col(:span='8').action-buttons
        el-button-group
          el-button(
              type='success'
              size='mini'
              icon='el-icon-circle-plus-outline'
              @click="() => showCreateTransactionForm('income')"
          ) Доход
          el-button(
              type='danger'
              size='mini'
              icon='el-icon-remove-outline'
              @click="() => showCreateTransactionForm('expense')"
          ) Расход
          el-button(size='mini' @click="() => transferFormShow = true" icon='el-icon-sort') Перевод

      el-col(:span="2")
        GroupSelect.group-select(size="small")
      el-col(:span='2').logout-button
        el-button(type='text', icon='el-icon-upload2' @click="logoutAction") Выход

    create-transaction-form(
      :type="createType"
      :visibleSync="createType"
      @updateVisibleStatus="() => showCreateTransactionForm(false)"
    )
    create-transfer-form(
      :isVisible='transferFormShow'
      :visibleSync="transferFormShow"
      @updateVisibleStatus="() => transferFormShow = false"
    )
</template>

<script>
import GroupSelect from '@/components/GroupSelect';
import CreateTransactionForm from '@/components/tools/CreateTransactionForm';
import CreateTransferForm from '@/components/tools/CreateTransferForm';

export default {
    name: 'HeaderBar',
    components: { GroupSelect, CreateTransferForm, CreateTransactionForm },
    data: () => ({
        activeLink: '',
        createType: undefined,
        transferFormShow: false,
    }),
    watch: {
        $route(newVal) {
            this.activeLink = newVal.path;
        },
    },
    mounted() {
        this.activeLink = this.$route.path;
    },
    methods: {
        logoutAction() {
            this.$store.dispatch('logout');
        },
        routeList() {
            return this.$router.options.routes.find(({ path }) => path === '/')
                .children;
        },
        showCreateTransactionForm(type) {
            this.createType = type;
        },
    },
};
</script>

<style scoped lang="scss">
.header {
    border-bottom: solid 1px #e6e6e6;

    .el-menu {
        border-bottom: 0;
    }

    .action-buttons {
        button {
            margin-top: 14px;
            padding: 7px 29px;
            font-weight: normal;
        }
    }

    .logout-button {
        margin-top: 10px;
    }
    .group-select {
        margin-top: 12px;
    }
}
</style>
