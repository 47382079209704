<template lang="pug">
  .property-rights-modal
    el-dialog(
      :title='`Настройка прав пользователя ${userEmail}`'
      :visible='!!user'
      @close='$emit("close-form")'
      class="rights-modal"
      :destroy-on-close="true"
    ).rights-modal
      .property-type-table(v-for='(propertyList, propertyType) in propertiesMap' v-if="propertyList.length")
        div.property-title {{ `Доступ к ${propertyTitle(propertyType)}` }}
        span.property-row(v-for='property in propertyList')
          el-checkbox-group(v-model='rights[propertyType.split("-")[0]]')
            el-checkbox(
              size='mini'
              :label='property.id'
              :key='property.id'
              :border="true"
            )
              | {{ property.name }}

      span.dialog-footer(slot='footer')
        el-button(:disabled="isLoading" @click='$emit("close-form")') Cancel
        el-button(type='primary' :loading="isLoading" @click='savePropertyRights') Confirm
</template>

<script>
import { mapGetters } from 'vuex';

import api from '@/utils/api';
import { CATEGORY_TYPE_LIST } from '@/utils/constants';

export default {
    name: 'UserSetupPropertyRightsModal',
    props: ['user'],
    data() {
        return {
            isLoading: false,
            userEmail: undefined,
            rights: { ...this.rightsMap },
        };
    },
    watch: {
        user(user) {
            if (!user) return;
            this.userEmail = user.email;
            this.rights = {
                ...this.rightsMap,
                ...user.propertyRights,
            };
        },
    },
    computed: {
        ...mapGetters(['propertiesData']),
        propertiesMap() {
            return Object.entries(this.propertiesData).reduce(
                (result, [propertyType, propertyList]) => {
                    if (propertyType === 'category') {
                        CATEGORY_TYPE_LIST.forEach(categoryType => {
                            result[
                                `category-${categoryType}`
                            ] = propertyList.filter(
                                ({ type }) => type === categoryType,
                            );
                        });
                    } else {
                        result[propertyType] = propertyList;
                    }

                    return result;
                },
                {},
            );
        },
        rightsMap() {
            return Object.keys(this.propertiesData).reduce(
                (obj, key) => ({ ...obj, [key]: [] }),
                {},
            );
        },
    },
    methods: {
        propertyRightCheckboxUpdate(propertyType, key, value) {
            const rights = this.rights[propertyType.split('-')[0]];

            if (value && !rights.includes(key)) {
                rights.push(key);
            } else if (!value && rights.includes(key)) {
                rights.splice(rights.indexOf(key), 1);
            }
        },
        async savePropertyRights() {
            this.isLoading = true;
            try {
                await api.post(`/admin/users/property-rights/${this.user.id}`, {
                    rights: this.rights,
                });
                this.$emit('close-form');
                this.$emit('reload-list');
            } finally {
                this.isLoading = false;
            }
        },
        propertyTitle(propertyType) {
            if (propertyType === 'account') return 'счетам';
            if (propertyType === 'project') return 'направлениям';
            if (propertyType === 'category-income') return 'категориям дохода';
            if (propertyType === 'category-expense')
                return 'категориям расхода';
            if (propertyType === 'tag') return 'проектам';
            return propertyType;
        },
    },
};
</script>

<style lang="scss">
.property-rights-modal {
    .el-dialog {
        max-width: 550px;
    }

    .property-type-table {
        text-align: left;

        .property-title {
            font-size: 1.1em;
            margin-top: 5px;
        }

        .property-row {
            > div.el-checkbox-group {
                display: inline-block;
            }

            &:not(:last-of-type) {
                margin-right: 5px;
            }
        }
    }
}
</style>
