<template lang="pug">
  .users-setup-tab(v-loading="isLoading")
    .users-table
      el-table(:data="userList")
        el-table-column(prop="id" label="ID" width="60px")

        el-table-column(prop="email" label="E-Mail")
          template(slot-scope='scope')
            span(v-if="editRow.id !== scope.row.id") {{ scope.row.email }}

            el-input(v-else size='mini' v-model='editRow.email' placeholder='Укажите E-Mail' autocomplete='off')

        el-table-column(prop="name" label="Имя")
          template(slot-scope='scope')
            span(v-if="editRow.id !== scope.row.id") {{ scope.row.name }}

            el-input(v-else size='mini' v-model='editRow.name' placeholder='Укажите имя' autocomplete='off')

        el-table-column(v-if='!isNaN(editRow.id)')
          template(slot-scope='scope')
            el-input(
              v-if="editRow.id === scope.row.id"
              size='mini'
              v-model='editRow.password'
              placeholder='Новый пароль (необязательно)'
              autocomplete='off'
              type="password"
            )

        el-table-column
          template(slot='header' slot-scope='scope')
            el-link(
                size='mini'
                type="primary"
                :underline="false"
                @click="createUserShowForm = true"
            ).add-user Создать пользователя

          template(slot-scope='scope')
            template(v-if="editRow.id !== scope.row.id")
              el-button(size='mini' @click='editProfileButton(scope.row)') Профиль
              el-button(size='mini' @click='editRightsUser = scope.row') Права
              el-button(size='mini' @click='editPropertyRightsUser = scope.row') Разрешения

            template(v-else)
              el-button(size='mini' @click='resetProfileEdit') Отмена
              el-button(size='mini' type='success' @click='saveProfileEdit(scope.row)') Сохранить

    user-setup-create-user-modal(
      :isVisible='createUserShowForm' @close-form='createUserShowForm = false'  @reload-list="loadUserList"
    )
    user-setup-rights-modal(
      :user='editRightsUser' @close-form="editRightsUser = false" @reload-list="loadUserList"
    )
    user-setup-property-rights-modal(
      :user='editPropertyRightsUser' @close-form="editPropertyRightsUser = false" @reload-list="loadUserList"
    )
</template>

<script>
import UserSetupCreateUserModal from '@/components/pages/setup/usersetup/UserSetupCreateUserModal';
import UserSetupPropertyRightsModal from '@/components/pages/setup/usersetup/UserSetupPropertyRightsModal';
import UserSetupRightsModal from '@/components/pages/setup/usersetup/UserSetupRightsModal';
import api from '@/utils/api';

const emptyEditProfile = () => ({
    id: undefined,
    name: undefined,
    email: undefined,
    password: undefined,
});

export default {
    name: 'UserSetupTab',
    components: {
        UserSetupPropertyRightsModal,
        UserSetupCreateUserModal,
        UserSetupRightsModal,
    },
    data: () => ({
        isLoading: false,
        userList: [],
        rightsDefinition: {},
        editRow: emptyEditProfile(),

        createUserShowForm: false,
        editRightsUser: false,
        editPropertyRightsUser: false,
    }),
    mounted() {
        this.loadUserList();
    },
    methods: {
        async loadUserList() {
            this.isLoading = true;
            try {
                const { data: userList } = await api.get('/admin/users');
                this.userList = userList;
            } finally {
                this.isLoading = false;
            }
        },
        editProfileButton(row) {
            this.editRow.id = row.id;
            this.editRow.name = row.name;
            this.editRow.email = row.email;
        },
        resetProfileEdit() {
            this.editRow = emptyEditProfile();
        },
        async saveProfileEdit() {
            this.isLoading = true;
            try {
                await api.put(`/admin/users/${this.editRow.id}`, this.editRow);
                this.editRow = emptyEditProfile();
                this.loadUserList();
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.users-setup-tab {
    .rights-modal {
        .el-dialog {
            max-width: 350px;
        }
    }
}
</style>
