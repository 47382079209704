export default function removeTimezoneOffset(date) {
    const dateArgs = [
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds(),
    ];
    return new Date(Date.UTC(...dateArgs));
}
