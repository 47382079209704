/* eslint-disable import/no-extraneous-dependencies */
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import Vue from 'vue';
import Argon from 'vue-argon-theme';

import App from '@/App.vue';
import router from '@/router/router';
import store from '@/store/store';
import filters from '@/utils/filters';
import './styles.scss';

filters(Vue);

Vue.use(Argon);
Vue.config.productionTip = false;

Vue.use(ElementUI, { locale });

new Vue({
    router,
    render: h => h(App),
    store,
}).$mount('#app');
