<template lang="pug">
.create-transaction-form
  el-dialog(
    :title="`${titleType} ${title}`"
    :visible="isVisible"
    @close="$emit('updateVisibleStatus', false)"
    @open="!editableRow ? resetForm() : ''"
    :close-on-click-modal="false"
    :destroy-on-close="true"
  )
    el-form(
      :model="form"
      :rules="rules"
      ref="form"
      :disabled="isLoading"
    )
      el-form-item(
        prop="accountId"
        label="Счет"
      )
        el-select(
          v-model="form.accountId"
          placeholder="Выберите счет"
          filterable
        ).select
          el-option-group(
            label="Аккаунты",
            v-if="properties.account && properties.account.length"
          )
            el-option(
              v-for="account in properties.account",
              :label="account.name",
              :value="account.id"
            )
              span(style="float: left") {{ account.name }}
              span(style="float: right; color: #8492a6; font-size: 0.85em") 
                | {{ CURRENCY_CHAR_MAP[account.currency] || account.currency }}

          el-option-group(
            label="Мерчанты",
            v-if="properties.merchants && properties.merchants.length"
          )
            el-option(
              v-for="merchant in properties.merchants",
              :label="merchant.name",
              :value="`${merchant.name}--${merchant.currency}`"
            )
              span(style="float: left") {{ merchant.name }}
              span(style="float: right; color: #8492a6; font-size: 0.85em") 
                | {{ CURRENCY_CHAR_MAP[merchant.currency] || merchant.currency }}

      el-form-item(
        prop="categoryPath"
        label="Категория"
      )
        CategoriesCascader(v-model="form.categoryPath")

      el-form-item(
        prop="geoId"
        label="ГЕО"
      )
        el-select.select(
          placeholder="Выберите ГЕО"
          v-model="form.geoId"
          filterable
          clearable
        )
          el-option(
            v-for="geo of propertiesData.geo"
            :key="geo.id"
            :label="geo.title"
            :value="geo.id"
          )

      el-form-item(
        prop="amount"
        label="Сумма операции"
      )
        el-input(
          v-model.number="form.amount"
          placeholder="Укажите сумму"
          autocomplete="off"
        )
          template(slot="append") {{ currency }}

      el-form-item(
        prop="fee"
        label="Комиссия за операцию (не обязательно)"
      )
        el-input(
          v-model.number="form.fee"
          placeholder="Укажите комиссию"
          autocomplete="off"
        )
          template(slot="append") {{ currency }}

      el-form-item(
        prop="date"
        label="Дата операции"
      )
        el-date-picker.datepicker-input(
          v-model="form.date"
          type="date"
          placeholder="Выберите дату"
        )

      el-form-item(
        prop="comment"
        label="Комментарий"
      )
        el-input(
          v-model="form.comment"
          type="textarea"
          :rows="2"
          placeholder="Комментарий (не обязательно)"
          autocomplete="off"
        )

    span.dialog-footer(slot="footer")
      el-button(
        @click="$emit('updateVisibleStatus', false)"
        :disabled="isLoading"
      ) Отмена
      el-button(
        type="primary"
        :loading="isLoading"
        @click="submitTransaction"
      ) Подтвердить

  insert-reason-form(
    :isVisible="showReasonModal"
    :isLoading="isLoading"
    @sendEditingTransaction="sendEditingTransaction"
    @updateReasonVisibleStatus="showReasonModal = false"
  )
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { editTransaction, createTransaction } from '@/api/transaction';
import CategoriesCascader from '@/components/tools/CategoriesCascader.vue';
import { CURRENCY_CHAR_MAP, DEFAULT_CURRENCY } from '@/utils/constants';
import removeTimezoneOffset from '@/utils/removeTimezoneOffset';

import InsertReasonForm from './InsertReasonForm';

const initFormData = row => {
    row = row || {};
    return {
        accountId: row.account_id
            ? row.account_id
            : row.merchant_name
            ? `${row.merchant_name}--${row.currency}`
            : null,
        categoryPath:
            row.categoryRelationPath && row.categoryRelationPath.length
                ? row.categoryRelationPath.split('.').map(i => +i)
                : [],
        amount: Math.abs(row.amount) || 0,
        fee: row.fee || 0,
        comment: row.comment || '',
        date: row.source_payout_date || null,
        transactionId: row.id || null,
        geoId: row.geo_id || null,
    };
};

export default {
    name: 'CreateTransactionForm',
    components: { CategoriesCascader, InsertReasonForm },
    // eslint-disable-next-line vue/require-prop-types
    props: ['type', 'editableRow'],
    data() {
        return {
            isLoading: false,
            form: initFormData(),
            CURRENCY_CHAR_MAP,
            showReasonModal: false,
        };
    },
    computed: {
        ...mapGetters([
            'propertiesData',
            'propertiesDataWithStaff',
            'transactionData',
            'groupId',
        ]),
        properties() {
            const properties =
                this.type === 'income'
                    ? this.propertiesData
                    : this.propertiesDataWithStaff;

            return properties;
        },
        title() {
            return this.type === 'income' ? 'дохода' : 'расхода';
        },
        titleType() {
            return this.isEditing ? 'Редактирование' : 'Создание';
        },
        currency() {
            let value = this.form['accountId'];
            if (!value) return DEFAULT_CURRENCY;
            let storeObj = parseInt(value) ? 'account' : 'merchants';
            if (storeObj === 'merchants') {
                value = value.split('--');
            }
            const result = this.properties[storeObj].find(data =>
                storeObj === 'account'
                    ? data.id === value
                    : data.name === value[0] && data.currency === value[1],
            );
            return result ? result.currency : DEFAULT_CURRENCY;
        },
        isVisible() {
            return !!this.type;
        },
        isEditing() {
            return !!this.editableRow;
        },
        rules() {
            const rules = {
                categoryPath: {
                    required: true,
                    message: 'Выберите категорию',
                    trigger: 'change',
                },
                amount: {
                    required: true,
                    message: 'Заполните поле',
                    trigger: 'change',
                },
                fee: {
                    required: true,
                    message: 'Заполните поле',
                    trigger: 'change',
                },
                date: {
                    required: true,
                    message: 'Выберите дату',
                    trigger: 'change',
                },
            };

            if (!this.isEditing) {
                rules.accountId = {
                    required: true,
                    message: 'Выберите аккаунт',
                    trigger: 'change',
                };
            }

            return rules;
        },
    },
    watch: {
        editableRow(value) {
            if (!value) return;

            this.form = initFormData(value);
        },
    },
    methods: {
        ...mapActions(['loadTransactionData', 'loadPropertiesData']),
        resetForm() {
            this.form = initFormData();
        },
        submitTransaction() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    this.isLoading = true;
                    try {
                        if (this.isEditing) {
                            return (this.showReasonModal = true);
                        } else {
                            await createTransaction({
                                type: this.type,
                                ...this.form,
                                amount:
                                    Math.abs(this.form.amount) *
                                    (this.type === 'income' ? 1 : -1),
                                groupId: this.groupId,
                                date: removeTimezoneOffset(this.form.date),
                            });
                        }

                        await Promise.all([
                            this.loadTransactionData(
                                this.transactionData.currentPage,
                            ),
                            this.loadPropertiesData(),
                        ]);
                        this.$emit('updateVisibleStatus', false);
                    } finally {
                        this.isLoading = false;
                    }
                }
            });
        },
        async sendEditingTransaction(reason) {
            this.isLoading = true;

            try {
                await editTransaction(this.editableRow.id, {
                    ...this.form,
                    source_payout_date: removeTimezoneOffset(
                        new Date(this.form.date),
                    ),
                    reason,
                    currency: this.currency,
                    account_id: this.form.accountId,
                    geo_id: this.form.geoId,
                    amount:
                        Math.abs(this.form.amount) *
                        (this.type === 'income' ? 1 : -1),
                });
                await Promise.all([
                    this.loadTransactionData(this.transactionData.currentPage),
                    this.loadPropertiesData(),
                ]);
                this.$emit('updateVisibleStatus', false);
            } finally {
                this.isLoading = false;
                this.showReasonModal = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.create-transaction-form {
    /deep/ {
        .select,
        .el-date-editor {
            width: 100%;
        }

        .el-form-item {
            margin-bottom: 15px;
        }

        .el-form-item__label {
            margin-bottom: 0;
            padding-bottom: 0;
            line-height: inherit;
        }
    }
}
</style>
