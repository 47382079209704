<template lang="pug">
  el-dialog(
    :title='`Создание пользователя`'
    :visible='isVisible'
    @close='$emit("close-form")'
    :destroy-on-close="true"
  )
    el-form(:model='form' :rules="rules")
      el-form-item(prop="name" label='Email')
        el-input(v-model='form.email' placeholder='Укажите E-Mail' autocomplete='new-password')
      el-form-item(prop="name" label='Имя')
        el-input(v-model='form.name' placeholder='Укажите имя' autocomplete='new-password')
      el-form-item(prop="password" label='Пароль')
        el-input(v-model='form.password' placeholder='Укажите пароль' type='password' autocomplete='new-password')

    span.dialog-footer(slot='footer')
      el-button(:disabled="isLoading" @click='$emit("close-form")') Отмена
      el-button(type='primary' :loading="isLoading" @click='createUserButton') Подтвердить

</template>

<script>
import api from '@/utils/api';

export default {
    name: 'UserSetupCreateUserModal',
    props: ['isVisible'],
    data: () => ({
        isLoading: false,
        form: {
            name: '',
            email: '',
            password: '',
        },
        rules: {
            name: {
                required: true,
                message: 'Заполните поле',
                trigger: 'change',
            },
            email: {
                required: true,
                message: 'Заполните поле',
                trigger: 'change',
            },
            password: {
                required: true,
                message: 'Заполните поле',
                trigger: 'change',
            },
        },
    }),
    methods: {
        async createUserButton() {
            this.isLoading = true;
            try {
                await api.post('/admin/users', this.form);

                this.$emit('close-form');
                this.$emit('reload-list');
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped></style>
