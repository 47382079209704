<template lang="pug">
  .main
    header-bar
    el-row.content-block
      el-col(:span="6"): account-table
      el-col(:span="18").page-content: router-view
</template>

<script>
import AccountTable from '@/components/AccountTable';
import HeaderBar from '@/components/HeaderBar';

export default {
    components: { AccountTable, HeaderBar },
};
</script>

<style scoped lang="scss">
.content-block {
    .page-content {
        min-height: 100vh;
        border-left: solid 1px #e6e6e6;
        padding: 0 5vh;
    }
}
</style>
