/**
 * @author feod0r
 */

import moment from 'moment';

import store from '@/store/store';
import { CURRENCY_CHAR_MAP, DEFAULT_CURRENCY } from '@/utils/constants';

export const thousandFormatter = num => {
    num = +num.toFixed(1);

    if (num <= 999.9 && num >= -999.9) return Number(num).toFixed(0);

    const arr = num.toLocaleString('en').split(',');
    const postFix = 'k'.repeat(arr.length - 1);
    const prefixNumber = `${arr[0]},${arr[1][0]}`;

    return prefixNumber + postFix;
};

export default Vue => {
    Vue.filter('moment', (dateInput, mask = 'DD/MM/YYYY HH:mm:ss') =>
        moment(new Date(dateInput))
            .subtract('3', 'hour')
            .format(mask),
    );

    Vue.filter('money', (amount, currency = DEFAULT_CURRENCY) => {
        const [whole, decimal = ''] = String(amount).split('.');
        const decimalLength = currency === 'BTC' ? 5 : 2;

        return [
            decimal
                ? whole.replace(/(.)(?=(\d{3})+$)/g, '$1,')
                : whole.replace(/(.)(?=(\d{3})+$)/g, '$1'),
            decimal ? '.' : '',
            decimal.substr(0, decimalLength),
            ' ',
            CURRENCY_CHAR_MAP[currency] || ` ${currency}`,
        ].join('');
    });

    Vue.filter('propertyData', (targetId, propertyType) => {
        const property = store.getters.propertiesDataWithRemoved[
            propertyType
        ]?.find(({ id }) => +id === +targetId);

        if (!property) return propertyType !== 'tag' ? `${targetId}` : '一';

        return property.name;
    });

    Vue.filter('thousands', thousandFormatter);
};
