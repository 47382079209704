<template lang="pug">
  el-dialog(
    :title='`Настройка прав пользователя ${userEmail}`'
    :visible='!!user'
    @close='$emit("close-form")'
    class="rights-modal"
    :destroy-on-close="true"
  ).rights-modal
    div(v-for='(label, key) in rightsDefinition')
      el-checkbox(
        :label='label'
        :key='key'
        border=''
        style="width: 100%"
        :value='rights.includes(key)'
        @change="value => editRightsCheckbox(key, value)"
      )

    span.dialog-footer(slot='footer')
      el-button(:disabled="isLoading" @click='$emit("close-form")') Cancel
      el-button(type='primary' :loading="isLoading" @click='saveUserRights') Confirm
</template>

<script>
import api from '@/utils/api';

export default {
    name: 'UserSetupRightsModal',
    props: ['user'],
    data: () => ({
        isLoading: false,
        userEmail: undefined,
        rights: [],
        rightsDefinition: {},
    }),
    watch: {
        user(user) {
            if (!user) return;
            this.userEmail = user.email;
            this.rights = [...user.rights];
        },
    },
    async mounted() {
        this.rightsDefinition = await api
            .get('/admin/users/rights')
            .then(({ data }) => data);
    },
    methods: {
        editRightsCheckbox(key, value) {
            const { rights } = this;

            if (value && !rights.includes(key)) {
                rights.push(key);
            } else if (!value && rights.includes(key)) {
                rights.splice(rights.indexOf(key), 1);
            }
        },
        async saveUserRights() {
            this.isLoading = true;
            try {
                await api.post(`/admin/users/rights/${this.user.id}`, {
                    rights: this.rights,
                });

                this.$emit('close-form');
                this.$emit('reload-list');
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped></style>
