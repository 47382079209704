<template lang="pug">
.wrapper(v-loading="loagingGetter")
    .filters-wrapper
        el-input.input-filter(placeholder='Введите название категории' v-model="filters.search" size="small")
        el-select.input-filter(v-model="filters.type" placeholder="Выберите тип категории" size="small")
            el-option(label='Все' value="all")
            el-option(label='Расход' value="expense")
            el-option(label='Доход' value="income")
        el-switch.input-filter(v-model="filters.showDeleted" inactive-text="Скрытые" @change="changeDeletedSwitcher")
        el-button(
            size='mini' 
            plain type="success" 
            icon="el-icon-plus" 
            @click="openDrawer('CategoryDrawer')"
        ) Добавить корневую категорию
        el-button(size='mini' plain type="warning" icon="el-icon-s-operation" disabled) Права
    el-tree(:data="structureGetter && structureGetter.result ? structureGetter.result : []"
        ref="categoriesTreeRef" 
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
        :filter-node-method="filterCategories"
    )
        .custom-tree-node(slot-scope='{ node, data }')
            div {{ data.name }}
            .actions
                el-button(type="primary" size='mini' plain @click="openDrawer('CategoryDrawer', data)")
                    | Добавить
                el-button(size='mini' plain @click="openDrawer('CategoryDrawer', data, 'edit')")
                    | Редактировать
                el-button(type="danger" size='mini' plain @click="hidden(data)")
                    | {{ data.deletedAt ? 'Показать' : 'Скрыть' }}

    CategoryDrawer(ref="CategoryDrawer" @onSubmit="getStructure")

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import CategoryDrawer from './CategoryDrawer.vue';

export default {
    name: 'TransactionCategoriesSetupPage',
    components: { CategoryDrawer },
    data: () => ({
        filters: { search: '', type: 'all', showDeleted: false },
    }),
    computed: {
        ...mapGetters('transactionCategoryRelations', [
            'structureGetter',
            'loagingGetter',
        ]),
    },
    watch: {
        'filters.search'(val) {
            this.$refs.categoriesTreeRef.filter(val);
        },
        'filters.type'(val) {
            this.$refs.categoriesTreeRef.filter(val);
        },
    },
    methods: {
        ...mapActions('transactionCategoryRelations', ['getStructureAction']),
        ...mapActions('transactionCategories', ['changeDeletedCategoryAction']),
        async hidden(data) {
            await this.$confirm(
                `Вы уверены, что хотите ${
                    data.deletedAt ? 'показать' : 'скрыть'
                } категорию ${data.name} и все его подкатегории?`,
                'Внимание',
                {
                    confirmButtonText: 'Продолжить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                },
            );
            await this.changeDeletedCategoryAction({
                categoryId: data.categoryId,
                delete: !data.deletedAt,
            });
            this.getStructure();
        },
        async getStructure() {
            this.getStructureAction(this.filters);
        },
        filterCategories(value, data) {
            if (!this.filters.search && this.filters.type === 'all')
                return true;

            const name = data.name.toLowerCase();
            const search = this.filters.search.toLowerCase();

            if (this.filters.type === 'all' && this.filters.search)
                return name.includes(search);

            return data.type === this.filters.type && name.includes(search);
        },
        changeDeletedSwitcher() {
            this.getStructure();
        },
        openDrawer(name, category, mode) {
            this.$refs[name].open(category);
            if (mode) {
                this.$refs[name].setMode(mode);
            }
        },
    },
};
</script>

<style lang="scss">
.el-tree-node__content {
    height: 35px !important;
}
</style>

<style scoped lang="scss">
.custom-tree-node {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    height: 15px;
}

.actions {
    display: flex;
    align-items: center;
}

.filters-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.input-filter {
    margin-right: 10px;
    &:last-child {
        margin-right: 0 !important;
    }
}
</style>
