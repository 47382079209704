/**
 * @author feod0r
 */
import store from '@/store/store';

const isRouteRequireAuth = route =>
    route.matched.some(record => record.meta.authorizationRequired);

export default async (to, from, next) => {
    if (!store.getters.isInitialLoaded) {
        store.commit('setInitialLoadRequestPending', true);
        await store.dispatch('initialLoad');
    }

    if (isRouteRequireAuth(to) && !store.getters.isAuthorized) {
        return next({
            path: '/login',
        });
    }

    if (store.getters.isAuthorized) {
        if (isRouteRequireAuth(from) && !isRouteRequireAuth(to)) {
            return next({
                path: '/',
            });
        }
    }

    return next();
};
