<template lang="pug">
.setup-page
    el-tabs(tab-position='top' @tab-click="tabClick")
      el-tab-pane(v-for="type in propertyTypeList")
        span(slot='label')
          | {{ type.name }}
          i(:class='type.icon')

        setup-table(:propertyType='type.key' :rows='propertiesDataWithStaff[type.key]' :categoryType="type.type")
    
      el-tab-pane(v-if="sessionRights.includes('admin')" name="transactions-category")
        span(slot='label')
          | Категории транзакций
          i(class='el-icon-folder-opened')

        TransactionCategoriesSettings

      el-tab-pane(v-if="sessionRights.includes('admin')")
        span(slot='label')
          | Пользователи
          i(class='el-icon-user')

        user-setup-tab

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SetupTable from '@/components/pages/setup/SetupTable';
// eslint-disable-next-line max-len
import TransactionCategoriesSettings from '@/components/pages/setup/TransactionCategoriesSettings/TransactionCategoriesSettings.vue';
import UserSetupTab from '@/components/pages/setup/usersetup/UserSetupTab';

export default {
    name: 'SetupPage',
    components: { UserSetupTab, SetupTable, TransactionCategoriesSettings },
    data: () => ({
        propertyTypeList: [
            {
                key: 'account',
                name: 'Счета',
                icon: 'el-icon-coin',
            },
            // {
            //     key: 'project',
            //     name: 'Направления',
            //     icon: 'el-icon-folder-opened',
            // },
            // {
            //     key: 'tag',
            //     name: 'Проекты',
            //     icon: 'el-icon-collection-tag',
            // },
            // {
            //     key: 'category',
            //     type: 'income',
            //     name: 'Категория дохода',
            //     icon: 'el-icon-circle-plus-outline',
            // },
            // {
            //     key: 'category',
            //     type: 'expense',
            //     name: 'Категория расхода',
            //     icon: 'el-icon-remove-outline',
            // },
        ],
    }),
    computed: {
        ...mapGetters(['propertiesDataWithStaff', 'sessionRights']),
    },
    methods: {
        ...mapActions('transactionCategoryRelations', ['getStructureAction']),
        tabClick(tab) {
            console.log('tab', tab);
            if (tab.name === 'transactions-category') {
                this.getStructureAction();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.setup-page {
    .el-tabs__header {
        width: 100%;

        i {
            margin-left: 10px;
        }
    }
}
</style>
