import Vue from 'vue';
import Vuex from 'vuex';

import actions from '@/store/actions';
import getters from '@/store/getters';
import mutations from '@/store/mutations';
import state from '@/store/state';

import transactionCategories from './transaction-categories';
import transactionCategoryRelations from './transaction-category-relations';

Vue.use(Vuex);

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
        transactionCategoryRelations,
        transactionCategories,
    },
});
