import Vue from 'vue';
import VueRouter from 'vue-router';

import Container from '@/components/Container';
import Login from '@/components/Login';
// import MerchantBalancesPage from '@/components/pages/merchantbalances/MerchantBalancesPage';
import TransactionListPage from '@/components/pages/operation-list/TransactionListPage';
import SetupPage from '@/components/pages/setup/SetupPage';
// import ChartsPage from '@/components/pages/statistic/ChartsPage';
// import StatisticPage from '@/components/pages/statistic/StatisticPage';
import routerGuard from '@/router/routerGuard';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        component: Login,
        meta: { authorizationRequired: false },
    },

    {
        path: '/',
        component: Container,
        redirect: '/operation-list',
        meta: { authorizationRequired: true },

        children: [
            {
                path: '/operation-list',
                component: TransactionListPage,
                meta: { title: 'Операции' },
            },
            // {
            //     path: '/statistics',
            //     component: StatisticPage,
            //     meta: { title: 'Сводка' },
            // },
            // {
            //     path: '/charts',
            //     component: ChartsPage,
            //     meta: { title: 'Сводка' },
            // },
            // {
            //     path: '/merchant-balances',
            //     component: MerchantBalancesPage,
            //     meta: { title: 'Балансы' },
            // },
            {
                path: '/setup',
                component: SetupPage,
                meta: { title: 'Настройки' },
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(routerGuard);

export default router;
