<template lang="pug">
  .setup-table
    .empty-notification(v-if='!rows.length')
      div {{ emptyText }}
      div: el-link(type='primary' @click="() => formVisibleStatus = true") Создать

    div(v-else).table-block
      el-table(:data='rowList', style='width: 100%')
        el-table-column(label='Имя')
          template(slot-scope='scope')
            span(v-if="editRow.id !== scope.row.id") {{ scope.row.name }}

            el-input(v-else size='mini' v-model='editRow.name' placeholder='Укажите название счета' autocomplete='off')

        template(v-if="propertyType === 'account'")
          el-table-column(label='Валюта' prop="currency")
            template(slot-scope='scope')
              span {{ CURRENCY_CHAR_MAP[scope.row.currency] }}

        template(v-if='propertyType === "tag"')
          el-table-column(label='Направление' prop="project")
            template(slot-scope='scope')
              span(v-if="editRow.id !== scope.row.id") {{ scope.row.projectId | propertyData('project') }}

              el-select(
                  v-else
                  size='mini'
                  v-model='editRow.projectId'
                  placeholder='Укажите направление'
                  style='width="100%"'
              )
                el-option(v-for='project in propertiesData.project' :label='project.name' :value='project.id')

        template(v-if='propertyType === "category"')
          el-table-column(label='Проект' prop="tag")
            template(slot-scope='scope')
              span(v-if="editRow.id !== scope.row.id") {{ scope.row.tagId | propertyData('tag') }}

              el-select(
                  v-else
                  size='mini'
                  v-model='editRow.tagId'
                  placeholder='Укажите проект'
                  style='width="100%"'
              )
                el-option(v-for='tag in propertiesData.tag' :label='tag.name' :value='tag.id')                       

        el-table-column(label='Operations' min-width="200px")
          template(slot='header' slot-scope='scope')
            el-link(
                size='mini'
                type="primary"
                :underline="false"
                @click="() => formVisibleStatus = true"
            ).add-link Создать новый

          template(slot-scope='scope')
            template(v-if="editRow.id !== scope.row.id")
              el-button(v-if="!scope.row.source" size='mini' @click='handleEdit(scope.row)') Изменить
              el-button(
                  v-if="!scope.row.source"
                  size='mini'
                  type='danger'
                  :loading="isLoading.deleteButton"
                  @click='remove(scope.row.id)'
              ) Удалить
            template(v-else)
              el-button(size='mini' @click='resetEditRow') Отмена
              el-button(
                  size='mini'
                  type='success'
                  :loading="isLoading.saveButton"
                  @click='save(scope.row)'
              ) Сохранить


    create-property-form(
      :property-type="propertyType"
      :category-type='categoryType'
      :visibleSync="formVisibleStatus"
      @updateVisibleStatus="updateFormVisibleStatus"
    )

</template>

<script>
import { mapGetters } from 'vuex';

import CreatePropertyForm from '@/components/tools/CreatePropertyForm';
import { CATEGORY_TYPE_MAP, CURRENCY_CHAR_MAP } from '@/utils/constants';

import api from '../../../utils/api';

const emptyEditRow = () => ({
    id: undefined,
    name: '',
    currency: '',
    categoryType: '',
    projectId: undefined,
});

export default {
    name: 'SetupTable',
    components: { CreatePropertyForm },
    props: ['propertyType', 'rows', 'categoryType'],
    data: () => ({
        CURRENCY_CHAR_MAP,
        CATEGORY_TYPE_MAP,
        formVisibleStatus: false,
        editRow: emptyEditRow(),
        isLoading: {
            deleteButton: false,
            saveButton: false,
        },
    }),
    computed: {
        ...mapGetters(['propertiesData']),
        rowList() {
            return this.rows.filter(row => {
                if (this.propertyType === 'category')
                    return row.type === this.categoryType;
                return true;
            });
        },
        emptyText() {
            const typeText = (() => {
                if (this.propertyType === 'account') return 'одного счета';
                if (this.propertyType === 'project')
                    return 'одного направления';
                if (this.propertyType === 'category')
                    return 'одной категории дохода/расхода';
                if (this.propertyType === 'tag') return 'одного проекта';

                return `Empty ${this.propertyType}`;
            })();

            return `На данный момент у вас нет ни ${typeText}`;
        },
    },
    methods: {
        updateFormVisibleStatus(val = true) {
            this.formVisibleStatus = val;
        },
        handleEdit(row) {
            this.editRow = row;
        },
        resetEditRow() {
            this.editRow = emptyEditRow();
        },
        async remove(propertyId) {
            await this.$confirm(
                'Вы уверены, что хотите удалить запись?',
                'Внимание',
                {
                    confirmButtonText: 'Продолжить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                },
            );

            this.isLoading.deleteButton = true;
            try {
                const targetData = {
                    propertyType: this.propertyType,
                    id: propertyId,
                };
                await api.delete('/properties-data/', { data: targetData });

                this.$store.commit('removeProperty', targetData);
            } finally {
                this.isLoading.deleteButton = false;
            }
        },
        async save(property) {
            this.isLoading.saveButton = true;
            const propertyTypeToUpdate =
                'tagId' in property ? 'tagId' : 'projectId';
            try {
                const updateData = {
                    name: this.editRow.name,
                    type: this.editRow.type,
                    [propertyTypeToUpdate]: this.editRow[propertyTypeToUpdate],
                };

                await api.put(`/properties-data/${this.propertyType}`, {
                    id: property.id,
                    updateData,
                });

                const purifiedUpdateData = Object.fromEntries(
                    Object.entries(updateData).filter(
                        ([, val]) => val !== undefined,
                    ),
                );

                this.$store.commit('updateProperty', {
                    propertyType: this.propertyType,
                    updateData: {
                        id: property.id,
                        ...purifiedUpdateData,
                    },
                });
                this.resetEditRow();
            } finally {
                this.isLoading.saveButton = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.table-block {
    max-width: 600px;

    .add-link:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 0;
        bottom: 0;
        border-bottom: 1px solid teal;
    }

    .add-link:after:hover {
        border-bottom: 0;
    }
}
</style>
