<template lang="pug">
  .login
    el-card
      template(v-if="step === STEPS.LOGIN_FORM")
        h2 Авторизация
        el-form.login-form(
          :model='model',
          :rules='rules',
          ref='form',
          @submit.native.prevent='login'
        )
          el-form-item(prop='username')
            el-input(
              v-model='model.username',
              placeholder='Username',
              prefix-icon='el-icon-user'
            )
          el-form-item(prop='password')
            el-input(
              v-model='model.password',
              placeholder='Password',
              type='password',
              prefix-icon='el-icon-unlock'
            )
          el-form-item
            el-button.login-button(
              :loading='loading',
              type='primary',
              native-type='submit',
              block=''
            ) Войти

      template(v-else-if="step === STEPS.GROUP_FORM")
        h2 Группа
        el-form.login-form(
          :model="{ group:this.$store.getters.groupId }"
          ref="groupForm"
          :rules="groupRules"
          @submit.native.prevent="groupSubmit"
        )
          el-form-item(prop="group")
            GroupSelect
          el-form-item
            el-button.login-button(
              type='primary',
              native-type='submit',
              block=''
            ) Продолжить
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import GroupSelect from '@/components/GroupSelect';
import { GROUP_KEY } from '@/utils/constants';

const STEPS = {
    LOGIN_FORM: 1,
    GROUP_FORM: 2,
};

export default {
    name: 'Login',
    components: { GroupSelect },
    data: () => ({
        model: { username: '', password: '' },
        step: STEPS.LOGIN_FORM,
        STEPS,
        loading: false,
        rules: {
            username: [
                {
                    required: true,
                    message: 'Username is required',
                    trigger: 'blur',
                },
                {
                    min: 4,
                    message: 'Username length should be at least 5 characters',
                    trigger: 'blur',
                },
            ],
            password: [
                {
                    required: true,
                    message: 'Password is required',
                    trigger: 'blur',
                },
                {
                    min: 5,
                    message: 'Password length should be at least 5 characters',
                    trigger: 'blur',
                },
            ],
        },
        groupRules: {
            group: [
                {
                    required: true,
                    message: 'Select group',
                    trigger: 'blur',
                },
            ],
        },
    }),
    computed: {
        ...mapGetters(['groupId']),
    },

    methods: {
        ...mapActions(['loadGroupList', 'loadPropertiesData', 'authorization']),

        async login() {
            if (!(await this.$refs.form.validate())) {
                return;
            }

            this.loading = true;
            try {
                await this.authorization(this.model);
                if (!localStorage.getItem(GROUP_KEY)) {
                    this.loadGroupList();
                    this.step = STEPS.GROUP_FORM;
                } else {
                    this.proceed();
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        proceed() {
            this.loadPropertiesData();
            this.$router.push({ path: '/' });
        },
        async groupSubmit() {
            if (!(await this.$refs.groupForm.validate())) {
                return;
            }
            this.proceed();
        },
    },
};
</script>

<style scoped lang="scss">
$teal: rgb(0, 124, 137);

.login {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    background: #102a43;
    background-image: url('../assets/LVs7-dots.png');
    background-size: contain;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-button--primary {
    background: $teal;
    border-color: $teal;

    &:hover,
    &.active,
    &:focus {
        background: lighten($teal, 7);
        border-color: lighten($teal, 7);
    }
}

.login .el-input__inner:hover {
    border-color: $teal;
}

.login .el-input__prefix {
    background: rgb(238, 237, 234);
    height: calc(100% - 2px);
    left: 1px;
    top: 1px;
    border-radius: 3px;

    .el-input__icon {
        width: 30px;
    }
}

.login .el-input input {
    padding-left: 35px;
}

.login .el-card {
    padding-top: 0;
    padding-bottom: 10px;
}

h2 {
    letter-spacing: 1px;
    padding-bottom: 20px;
}

a {
    color: $teal;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        color: lighten($teal, 7);
    }
}

.login .el-card {
    width: 340px;
    display: flex;
    justify-content: center;
}
</style>
