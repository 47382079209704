<template lang="pug">
.main-page
    transaction-filters
    transaction-table
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import TransactionFilters from '@/components/pages/operation-list/TransactionFilters';
import TransactionTable from '@/components/pages/operation-list/TransactionTable';

export default {
    name: 'TransactionListPage',
    components: { TransactionFilters, TransactionTable },
    computed: {
        ...mapGetters(['groupId']),
    },
    watch: {
        groupId(val) {
            if (val) {
                this.resetTransactionFilter();
                this.loadTransactionData();
            }
        },
    },
    methods: {
        ...mapActions(['loadTransactionData']),
        ...mapMutations(['resetTransactionFilter']),
    },
};
</script>

<style scoped></style>
