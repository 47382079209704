<template lang="pug">
.wrapper
    el-cascader.full(
        :size="size"
        :value="value" 
        :options="options" 
        @change="handleChange" 
        placeholder="Выберите категорию" 
        :props="{label: 'name', value: 'id', multiple}"
        clearable
        filterable
        collapse-tags
        :loading="true")

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'CategoriesCascager',
    // eslint-disable-next-line vue/require-prop-types
    props: ['value', 'size', 'multiple'],
    data() {
        return {};
    },
    computed: {
        ...mapGetters('transactionCategoryRelations', [
            'structureGetter',
            'loagingGetter',
        ]),
        options() {
            return this.structureGetter && this.structureGetter.result
                ? this.structureGetter.result
                : [];
        },
    },
    mounted() {
        this.getStructureAction({ forSelect: true });
    },
    methods: {
        ...mapActions('transactionCategoryRelations', ['getStructureAction']),
        handleChange(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}
.full {
    width: 100%;
}
</style>
