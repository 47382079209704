/**
 * @author feod0r
 */

import { MERCHANTS_LIST } from '../utils/constants';

export default {
    isAuthorized: state => state.auth.status === true,
    isInitialLoaded: state => state.isInitialLoaded,
    sessionRights: state => state.auth.rights,
    propertiesDataWithStaff: state => {
        if (state.propertiesData && state.propertiesData.merchants) {
            Object.entries(MERCHANTS_LIST).forEach(([name, currencyList]) =>
                currencyList.forEach(currency => {
                    state.propertiesData.merchants.push({ currency, name });
                }),
            );
        }

        return Object.fromEntries(
            Object.entries(state.propertiesData).map(([key, list]) => [
                key,
                list.filter(v => !v.is_removed),
            ]),
        );
    },
    propertiesData: state => {
        return Object.fromEntries(
            Object.entries(state.propertiesData).map(([key, list]) => [
                key,
                list.filter(v => !v.is_removed && v.source !== 'staff'),
            ]),
        );
    },
    propertiesDataWithRemoved: state => {
        return state.propertiesData;
    },
    transactionData: state => state.transactionData,
    transactionDataFilters: state => state.transactionData.filters,
    merchantBalancesData: state => state.merchantBalancesData,
    group: state => state.group,
    groupId: state => state.group.selected,
};
