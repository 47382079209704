<template lang="pug">
  .create-property-form
    el-dialog(
      :title='`Создание ${title}`'
      :visible='visibleSync'
      @close='() => $emit("updateVisibleStatus", false)'
      :destroy-on-close="true"
    )
      el-form(:model='form' :rules="rules" ref="form")

        template(v-if='propertyType === "tag"')
          el-form-item(prop="projectId" label='Направление')
            el-select(v-model='form.projectId' placeholder='Укажите направление' style='width="100%"')
              el-option(v-for='project in propertiesData.project' :label='project.name' :value='project.id')

        el-form-item(prop="name" label='Название')
            el-input(v-model='form.name' placeholder='Укажите название' autocomplete='off')

        template(v-if='propertyType === "category"')
          el-form-item(prop="tagId" label='Проект')
            el-select(v-model='form.tagId' placeholder='Укажите проект' style='width="100%"')
              el-option(v-for='tag in propertiesData.tag' :label='tag.name' :value='tag.id')

            el-form-item(prop="name" label='Название')
            el-input(v-model='form.name' placeholder='Укажите название' autocomplete='off')
        
        template(v-if='propertyType === "account"')
          el-form-item(prop="currency" label='Валюта счета')
            el-select(v-model='form.currency' placeholder='Укажите валюту счета' style='width="100%"')
              el-option(v-for='(char, key) in CURRENCY_CHAR_MAP' :label='char', :value='key')
                span {{ char }} - {{ key }}

          el-form-item(prop="amount" label='Начальный баланс')
            el-input(v-model.number='form.amount' placeholder='Укажите начальный баланс' autocomplete='off')

      span.dialog-footer(slot='footer')
        el-button(@click='() => $emit("updateVisibleStatus", false)' :disabled="isLoading") Cancel
        el-button(type='primary' :loading="isLoading" @click='addAction') Confirm

</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import {
    CATEGORY_TYPE_MAP,
    CURRENCY_CHAR_MAP,
    DEFAULT_CURRENCY,
} from '@/utils/constants';

import api from '../../utils/api';

export default {
    name: 'CreatePropertyForm',
    props: ['propertyType', 'visibleSync', 'categoryType'],
    data() {
        const propertyTypeSpecifiedValidator = targetType => {
            const targetTypes = Array.isArray(targetType)
                ? targetType
                : [targetType];

            return (rule, value, callback) => {
                if (targetTypes.includes(this.propertyType)) {
                    if (!value && value !== 0)
                        return callback(new Error('Заполните поле'));
                }

                callback();
            };
        };

        return {
            isLoading: false,
            form: {
                name: '',
                currency: DEFAULT_CURRENCY,
                amount: 0,
                projectId: null,
                tagId: null,
            },
            rules: {
                name: {
                    trigger: 'change',
                    validator: (rule, value, callback) =>
                        value && value.trim()
                            ? callback()
                            : callback(new Error('Заполните поле')),
                },
                currency: {
                    validator: propertyTypeSpecifiedValidator('account'),
                    trigger: 'change',
                },
                amount: {
                    validator: propertyTypeSpecifiedValidator('account'),
                    trigger: 'change',
                },
                projectId: {
                    validator: propertyTypeSpecifiedValidator([
                        'category',
                        'tag',
                    ]),
                    trigger: 'change',
                },
            },
            CURRENCY_CHAR_MAP,
            CATEGORY_TYPE_MAP,
        };
    },
    computed: {
        ...mapGetters(['propertiesData', 'groupId']),
        title() {
            if (this.propertyType === 'account') return 'счета';
            if (this.propertyType === 'project') return 'направления';
            if (this.propertyType === 'category') {
                return `категории ${
                    this.categoryType === 'income' ? 'дохода' : 'расхода'
                }`;
            }
            if (this.propertyType === 'tag') return 'проекта';
            return this.propertyType;
        },
    },
    methods: {
        ...mapMutations(['addProperty']),
        addAction() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    this.isLoading = true;
                    try {
                        const { data: propertyRow } = await api.post(
                            '/properties-data/',
                            {
                                propertyType: this.propertyType,
                                categoryType: this.categoryType,
                                ...this.form,
                                name: this.form.name.trim(),
                                groupId: this.groupId,
                            },
                        );
                        // Нет возможности (или бэкенд не захотел делать) получить "actualBalance" при POST запросе
                        // пришлось написать этот костыль из-за одного поля 😰
                        if (this.propertyType === 'account') {
                            propertyRow.actualBalance = 0;
                        }
                        this.addProperty({
                            propertyType: this.propertyType,
                            propertyRow,
                        });
                        this.$emit('updateVisibleStatus', false);
                    } finally {
                        this.isLoading = false;
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss">
.create-property-form {
    .el-dialog__wrapper {
        > div {
            max-width: 350px;

            .el-select {
                width: 100%;
            }
        }
    }
}
</style>
