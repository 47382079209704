/**
 * @author feod0r
 */
import { initialAuthState, initialTransactionDataFilters } from '@/store/state';

export default {
    setInitialLoaded(state) {
        state.isInitialLoaded = true;
    },
    setInitialLoadRequestPending(state, value) {
        state.isInitialLoadRequestPending = value;
    },
    setAuthorized(state, { user, rights }) {
        state.auth.user = user;
        state.auth.rights = rights;
        state.auth.status = true;
    },
    logout(state) {
        state.auth = initialAuthState();
    },
    updatePropertiesData(state, propertiesMap) {
        Object.keys(state.propertiesData).forEach(propertyName => {
            state.propertiesData[propertyName] = propertiesMap[propertyName];
        });
    },
    addProperty(state, { propertyType, propertyRow }) {
        state.propertiesData[propertyType].push(propertyRow);
    },
    updateProperty(state, { propertyType, updateData: { id, ...updateData } }) {
        Object.assign(
            state.propertiesData[propertyType].find(row => row.id === id),
            updateData,
        );
    },
    removeProperty(state, { propertyType, id }) {
        const property = state.propertiesData[propertyType].find(
            row => row.id === id,
        );
        if (property) {
            property.is_removed = 1;
        }
    },
    setIsMerchantBalancesLoading(state, value) {
        state.merchantBalancesData.isLoading = value;
    },
    setMerchantBalancesData(state, { rows }) {
        state.merchantBalancesData.rows = rows;
    },
    setIsTransactionLoading(state, value) {
        state.transactionData.isLoading = value;
    },
    setTransactionData(state, data) {
        const list = data.rows;
        delete data.rows;
        state.transactionData = { ...state.transactionData, ...data, list };
    },
    setTransactionPage(state, page) {
        state.transactionData.currentPage = page;
    },
    setTransactionFilters(state, filters) {
        state.transactionData.filters = {
            ...filters,
        };
    },
    resetTransactionFilter(state) {
        Object.assign(
            state.transactionData.filters,
            initialTransactionDataFilters(),
        );
    },
    addTransactionFilter(state, { type, id }) {
        state.transactionData.filters[type].push(id);
    },
    removeTransactionFilter(state, { type, id }) {
        state.transactionData.filters[type].splice(
            state.transactionData.filters[type].indexOf(id),
            1,
        );
    },
    setGroupListLoadStarted(state) {
        state.group.isLoading = true;
    },
    setGroupListLoadSuccess(state, list) {
        state.group.isLoading = false;
        state.group.list = list;
    },
    setGroupListLoadError(state) {
        state.group.isLoading = false;
        state.group.list = [];
    },
    setGroupValue(state, val) {
        state.group.selected = val;
    },
    setTransactionSort(state, { prop, order }) {
        state.transactionData.sort.order = order;
        state.transactionData.sort.prop = prop;
    },
    resetTransactionSort(state) {
        state.transactionData.sort.order = null;
        state.transactionData.sort.prop = null;
    },
};
